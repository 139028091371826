import styled from 'styled-components';
import PdfIcon from '../../images/icon-pdf-large.png';

export const MainDiv = styled.div`
  color: #666;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
`;
export const LinkStyle = styled.a`
  display: block;
  position: relative;
  padding: 16px 16px 16px 75px;
  margin: 0;
  border: 2px solid #e9e6e4;
  text-decoration: none;
  color: #1fb25a;
  :hover{
    color: #24a0df;
    border: 2px solid #24a0df;
  }
  ::before {
    background: url(${PdfIcon}) no-repeat top left;
    background-size: 60px auto;
    display: inline-block;
    position: absolute;
    top: -8px;
    left: -2px;
    width: 60px;
    height: 75px;
    content: '';
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 40px 0 40px 0;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #969696;
`;

export const LinkStyle1 = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #1fb25a;
  :hover {
    text-decoration: none;
    color: #0397d6;
    outline: none;
  }
`;

export const ImageStyle = styled.img`
  height: auto !important;
  width: auto !important;
  border: 0;
  vertical-align: top;
  margin: 0 0 30px 0;
  padding: 0;
  @media (max-width: 399px) {
    width: 100% !important;
    height: auto !important;
  }
`;
